import { PostgrestFilterBuilder } from "@supabase/postgrest-js";
import { createBrowserClient } from "@supabase/ssr";
import type { Database } from "../types";

export const createClient = () =>
  createBrowserClient<Database>(
    process.env.NEXT_PUBLIC_SUPABASE_URL!,
    process.env.NEXT_PUBLIC_SUPABASE_ANON_KEY!,
  );

export { PostgrestFilterBuilder };
